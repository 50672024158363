"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var LanguageContext_d_1 = require("@contexts/LanguageContext/LanguageContext.d");
var useLanguage_1 = require("@hooks/useLanguage");
var SwitchLanguage = function () {
    var _a = (0, useLanguage_1.useLanguage)(), language = _a.language, setLanguage = _a.setLanguage;
    var handleSwitchLanguage = function (value) {
        setLanguage(value);
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex p-2 px-4" }, { children: [(0, jsx_runtime_1.jsx)("button", __assign({ id: "BR", onClick: function () { return handleSwitchLanguage("BR"); }, className: "m-2 flex items-center p-2 " }, { children: (0, jsx_runtime_1.jsx)("img", { src: LanguageContext_d_1.TRANSLATE.BR.image, alt: "", className: "block h-auto w-5 flex-shrink-0" }) })), (0, jsx_runtime_1.jsx)("button", __assign({ id: "US", onClick: function () { return handleSwitchLanguage("US"); }, className: "m-2 flex items-center p-2" }, { children: (0, jsx_runtime_1.jsx)("img", { src: LanguageContext_d_1.TRANSLATE.US.image, alt: "", className: "block h-auto w-5 flex-shrink-0" }) })), (0, jsx_runtime_1.jsx)("button", __assign({ id: "ES", onClick: function () { return handleSwitchLanguage("ES"); }, className: "m-2 flex items-center p-2 " }, { children: (0, jsx_runtime_1.jsx)("img", { src: LanguageContext_d_1.TRANSLATE.ES.image, alt: "", className: "block h-auto w-5 flex-shrink-0" }) }))] })));
};
exports.default = SwitchLanguage;
