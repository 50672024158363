"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRANSLATE = void 0;
exports.TRANSLATE = {
    "pt-BR": {
        code: "BR",
        language: "BR",
        image: "https://vetores.org/d/bandeira-do-brasil.svg",
        name: "Português",
    },
    BR: {
        code: "BR",
        language: "BR",
        image: "https://vetores.org/d/bandeira-do-brasil.svg",
        name: "Português",
    },
    US: {
        code: "US",
        language: "US",
        image: "https://vetores.org/d/bandeira-estados-unidos.svg",
        name: "English",
    },
    ES: {
        code: "ES",
        language: "ES",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/750px-Flag_of_Spain.svg.png",
        name: "Espanhol",
    },
};
