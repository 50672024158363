"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePath = void 0;
var path_1 = require("@router/path");
var useAuth_1 = require("./useAuth");
var authenticatedPath = [
    {
        id: "home",
        name: "HOME",
        to: path_1.PATH_AUTHORIZED.DEFAULT.DEFAULT,
    },
    {
        id: "create-event",
        name: "CREATE_EVENT",
        to: path_1.PATH_AUTHORIZED.PRODUCER.EVENT_PAGE_CREATE,
    },
    {
        id: "my-events",
        name: "MY_EVENTS",
        to: path_1.PATH_AUTHORIZED.PRODUCER.EVENT_PAGE_MY_EVENTS,
    },
    {
        id: "my-tickets",
        name: "MY_TICKETS",
        to: path_1.PATH_AUTHORIZED.CONSUMER.MY_TICKETS,
    },
    {
        id: "logout",
        name: "LOGOUT",
        to: "/sair",
    },
];
var unAuthenticatedPath = [
    {
        id: "",
        name: "SIGNIN",
        to: path_1.PATH_UNAUTHORIZED.ENTRAR,
    },
];
var usePath = function () {
    var isAuthenticated = (0, useAuth_1.useAuth)().isAuthenticated;
    var path = isAuthenticated ? authenticatedPath : unAuthenticatedPath;
    return { path: path };
};
exports.usePath = usePath;
