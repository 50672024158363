"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATH_UNAUTHORIZED = exports.PATH_AUTHORIZED = void 0;
exports.PATH_AUTHORIZED = {
    CONSUMER: {
        EVENT_PAGE: "/evento/:hash",
        TERMS: "/termos",
        ALIAS: "/eventos/:hash",
        MY_TICKETS: "/meusingressos",
        EVENT_PAGE_PAYMENT: "/evento/:hash/pagamento",
        EVENT_PAGE_BUY: "/evento/:hash/comprar",
        EVENT_PAGE_THANKS: "/evento/:hash/obrigado",
        ASSISTIR: "/evento/:hash/assistir",
        EVENT_WIDGET_PAGE: "/evento/:hash/widget",
    },
    PRODUCER: {
        EVENT_PAGE_MY_EVENTS: "/produtor/meuseventos",
        EVENT_PAGE_CREATE: "/produtor/evento/criar",
        EVENT_PAGE_EDIT: "/produtor/evento/:hash/editar",
        EVENT_PAGE_ANALYTICS: "/produtor/evento/:hash/analytics",
        EVENT_SHARE: "/produtor/evento/:hash/divulgar",
        EVENT_PAGE_MY_LIVE: "/produtor/evento/:hash/assistir",
        DEFAULT: "/",
    },
    DEFAULT: {
        ENTRAR: "/entrar",
        DEFAULT: "/",
    },
    NOT_FOUND: "*",
};
exports.PATH_UNAUTHORIZED = {
    DEFAULT: "/",
    EVENT_WIDGET_PAGE: "/evento/:hash/widget",
    EVENT_PAGE: "/evento/:hash",
    EVENT_PAGE_BUY: "/evento/:hash/comprar",
    EVENT_PAGE_WATCH: "/evento/:hash/assistir",
    ENTRAR: "/entrar",
    TERMS: "/termos",
    RESET: "/redefinir/:hash",
    ALIAS: "/eventos/:hash",
    SIGN_IN: "/signin",
    SIGN_UP: "/signup",
    NOT_FOUND: "*",
    PRODUCER: "/produtor/*",
};
