"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHeader = void 0;
var ConfigContext_1 = require("@contexts/ConfigContext/ConfigContext");
var react_1 = require("react");
var useHeader = function () {
    var _a = (0, react_1.useContext)(ConfigContext_1.ConfigContext), showHeader = _a.showHeader, hideHeader = _a.hideHeader;
    return { hideHeader: hideHeader, showHeader: showHeader };
};
exports.useHeader = useHeader;
