"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendGA = void 0;
var sendGA = function (_a) {
    var event = _a.event, pageView = _a.pageView, pageTitle = _a.pageTitle, visitor = _a.visitor;
    try {
        return window.dataLayer.push("event", event, {
            page_title: pageTitle,
            page_location: pageView,
        });
    }
    catch (e) { }
};
exports.sendGA = sendGA;
