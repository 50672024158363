"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaymentStatus = void 0;
var typeStatus = {
    PENDING: "Pendente",
    RELEASED: "Pago",
    null: "Não informado",
};
var getPaymentStatus = function (type) { return typeStatus[type]; };
exports.getPaymentStatus = getPaymentStatus;
