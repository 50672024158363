"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAGE = exports.LOCALE = void 0;
exports.LOCALE = {
    BR: {
        ACTION: {
            HOME: "Home",
            CREATE_EVENT: "Criar Evento",
            MY_EVENTS: "Meus Eventos",
            MY_TICKETS: "Meus Ingressos",
            LOGOUT: "Sair",
            SIGNIN: "Entrar",
        },
        COMPANY: {
            NAME: "DeVideo",
            COPYRIGHT: "Copyright 2023",
        },
        ACCOUNT: {
            FORGOT_PASSWORD: "Esqueci minha senha!",
            SIGN_IN: "Entrar",
            SIGN_IN_GOOGLE: "Entrar com Google",
            SIGN_UP: "Cadastrar",
            RESET_PASSWORD: "Redefinir Senha",
        },
        SIGNUP: {
            H1: "Criar nova conta",
            FORM1: "Nome",
            FORM2: "E-mail",
            FORM3: "Senha",
            CHK: "Li e aceito os termos e condições de uso",
            LINK: "Politica de Privacidade",
        },
        HOME: {
            HEADER: {},
            MAIN: {
                H1: "Lives com venda de ingressos",
                TEXT2: "Comece agora mesmo a transmitir e monetizar com seus eventos online",
                //TEXT3: "Aqui o seu conteúdo tem a plataforma que precisa e seu público te dá o reconhecimento que você merece.",
                TEXT4: "Quero Começar A Fazer Lives",
            },
            MIDDLE: {
                H1: "Tudo o que você precisa para seu Evento",
                TAB1: "Lives RTMP em Full HD",
                DESC1: "Transmita usando mesmo padrão que Youtube e Twitch, usando OBS Studio, vMix, etc.",
                TAB2: "Informação do seu consumidor",
                DESC2: "Conheça a sua audiência com relatórios de quem acessou sua live",
                TAB3: "Bilheteria do evento",
                DESC3: "Aceite o que for mais conveniente ao seu público como Crédito, Pix, Google Pay e Apple Pay",
                TAB4: "Chat para interação com sua base",
                DESC4: "Torne sua audiência em uma comunidade e interaja você também com seu público",
                TAB5: "Repasse em até 3 dias após o evento",
                DESC5: "O repasse acontece assim que houver confirmação que a live aconteceu",
                TAB6: "Suporte ao criador de conteúdo",
                DESC6: "Suporte em tempo real de um time com muito conhecimento de streaming",
                TAB7: "Página do produtor",
                DESC7: "Tenha todos os seus eventos publicados em um só lugar e prontos para venda",
                TAB8: "AirPlay e Chromecast",
                DESC8: "Seu consumidor vai assistir sua live no conforto do sofá, usando Chromecast ou AirPlay",
                TAB9: "Trasmissão Global",
                DESC9: "A entrega do conteúdo é global, fale com seu público onde quer que ele esteja",
                TAB10: "Dashboard de seus eventos",
                DESC10: "Dashboard com as informações mais relevantes de venda e consumo da sua live",
                TAB11: "Multi formato",
                DESC11: "O seu público vai escolher onde quer assistir sua live, sendo mobile, web ou tv",
                TAB12: "Plataforma DIY - Faça você mesmo",
                DESC12: "Crie sua live do seu jeito, escolhendo tudo que é mostrado na página do evento",
            },
            FORMATS: {
                H1: "Formatos",
                H1DIY: "DIY - Faça você mesmo",
                H2DIY: "Crie seu próprio evento e divulgue para sua audiência",
                TEXT1: "Crie o seu evento. Escolha as imagens, textos e preço do seu evento",
                TEXT2: "Divulgue nos seus canais",
                TEXT3: "Coloque um widget de venda do seu ingresso no seu site",
                TEXT4: "Monitore as vendas e fale com seu público antes e durante o evento",
                BTN1: "Criar Live",
                H1PAR: "Parceria Profissional",
                H2PAR: "Entre em contato conosco e vamos começar uma parceria de sucesso",
                TEXT5: "Grandes eventos, com grandes públicos ou ticket alto com condições especiais",
                TEXT6: "4K, Multi câmeras, Multi palcos ou redundância de entrega de vídeo",
                TEXT7: "Aplicações Web, mobile ou App SmarTV personalizados para o seu negócio",
                TEXT8: "Personalização do player, página do evento ou experiência",
                BTN2: "Entrar em contato",
            },
            CONTACTUS: {
                H1: "Ficou com dúvidas? Quer saber mais?",
                TEXT1: "Nome",
                TEXT2: "E-Mail",
                TEXT3: "Telefone",
                TEXT4: "Mensagem",
                BTN1: "Enviar mensagem",
            },
            FAQ: {
                H1: "Dúvidas frequentes",
                TEXT1: "Como funciona a monetização do evento?",
                RESP1: "A nossa plataforma vende os ingressos e lida com todo o processo de compra e acesso do cliente a sua live. Depois que o evento é finalizado, aguardamos alguns dias para confirmação que o evento aconteceu e eventuais reembolsos aconteçam caso necessário, fazemos o repasse de 80% do que foi cobrado como ingresso para o produtor do evento e ficamos com 20% que engloba todos os custos da plataforma de streaming e cobrança de ingressos.",
                TEXT2: "O que é uma live RTMP?",
                RESP2: "RTMP é o protocolo mais comum utilizado em lives do Youtube, Twitch e etc. Ele pode ser usado tanto para criadores de conteúdo usando o OBS Studio que é um software gratuito ou para lives profissionais usando vMix em um setup profissional de uma empresa de captação de eventos.",
                TEXT3: "A DeVideo faz a captação do evento? Filmagem, sonorização, etc.",
                RESP3: "Não, somos dedicados a streaming e buscamos que as empresas que atuam na captação de eventos nos utilize para ampliar a sua atuação e sejam nossas parceiras.",
                TEXT4: "Quantas pessoas podem assistir a Live?",
                RESP4: "Na plataforma de faça você mesmo, onde o produtor / criador de conteúdo cria sua própria live e transmite (DIY), sugerimos um público de até 200 mil pessoas, para públicos maiores sugerimos que entrem em contato conosco para parceria comercial.",
                H2: "Timeline das jornadas",
                PROD1: "Produtor",
                PROD2: "Criar evento",
                PROD3: "Personalizar evento",
                PROD4: "Publicar evento",
                PROD5: "Divulgar evento",
                PROD6: "Apresentar Live",
                PROD7: "Receber repasse",
                COMP1: "Comprador",
                COMP2: "Comprar Ingresso",
                COMP3: "Assistir Live",
            },
            FOOTER: {
                LINK1: "Home",
                LINK2: "Criar Evento",
                LINK3: "Meus Ingressos",
                LINK4: "Feedback",
                LINK5: "Preciso de Ajuda",
                LINK6: "Termos de Uso",
            },
            LOGGED: {
                H1: "Meus ingressos",
                H2: "Meus Eventos",
                TEXT1: "Nenhum evento encontrado. Use Criar Evento para criar seu evento.",
                TEXT2: "Nenhum ingresso comprado até o momento.",
            },
        },
        EVENT: {
            MAIN: {
                H1: "Sobre o evento",
                H2: "Produtor do Evento",
                BTN1: "Comprar",
                BTN2: "Indisponível",
                BTN3: "Ver todos os eventos de ",
            },
            ALIAS: {
                BTN1: "Ver",
            },
            FORM: {
                NAME: "Nome do Evento",
                BTN: "Avançar",
                TMLH1: "Timeline da criação e publicação do evento",
                TML1: "Criar evento, informando somente o nome",
                TML2: "Personalizar com foto, descrição e valor do ingresso",
                TML3: "Informar detalhes do Produtor",
                TML4: "Publicar o evento liberando para venda",
                TML5: "Divulgar o evento, anunciando a página de venda",
                TML6: "Apresentar o evento na data e hora marcada",
                TML7: "Receber o repasse dos ingressos vendidos",
                TMLB1: "Evento não publicado",
                TMLB2: "Evento publicado",
            },
            EDIT: {
                H1: "Editar Evento",
                H2: "Finalize criação do seu evento.",
                TEXT1: "Poster do evento *",
                TEXT2: "PNG, JPG, GIF até 10MB",
                TEXT3: "Detalhes do evento",
                TEXT4: "Descrição do evento *",
                TEXT5: "Data e hora de início da live *",
                TEXT6: "Duração - Horas *",
                TEXT7: "Preço Ingresso",
                TEXT8: "Link de vídeo promocional do Youtube",
                TEXT9: "Produtor do evento",
                TEXT10: "O produtor do evento concentra todos os eventos de assunto, grupo ou contrato, uma mesma conta pode ter vários produtores, pense que cada produtor terá sua própria página de eventos, entenda como um canal onde seu consteúdo será agrupado.",
                TEXT11: "Nome do produtor",
                TEXT12: "Apelido - devideo.com.br/eventos/apelido (sem espaços ou caracteres especiais)",
                TEXT13: "Descrição do produtor",
                TEXT14: "Poster do produtor",
                TEXT15: "Produtor sem foto, use o editar abaixo",
                TEXT16: "Não há produtor cadastrado",
                TEXT17: "Todos os eventos do produtor podem ser encontrados em devideo.com.br/*nomedoprodutor*",
                TEXT18: "Use Editar produtor para customizar o produtor inicial que criamos para você e se precisar criar mais um produtor use Criar Produtor abaixo.",
                BTN1: "Selecionar arquivo de imagem",
                BTN2: "Clique aqui e veja se o link do Youtube está correto",
                BTN3: "Editar produtor",
                BTN4: "Selecionar",
                BTN5: "Selecionado",
                BTN6: "Criar Produtor",
                BTN7: "Salvar e visualizar alterações",
                BTN8: "Salvar e Publicar evento",
                BTN9: "Alterar Poster",
                BTN10: "Descartar",
                BTN11: "Salvar",
                COIN: "Moeda",
            },
            LIVE: {
                TEXT1: "Servidor",
                TEXT2: "Chave de Transmissão",
                BTN1: "Copiar",
                BTN2: "Área do consumidor",
            },
            LINKS: {
                H1: "Links para divulgação",
                TEXT1: "Página do evento",
                TEXT2: "Compra / Checkout do evento",
                TEXT3: "Widget para inserção no seu site de vendas",
                BTN1: "Copiar",
                BTN2: "Ver",
            },
            LEFTBAR: {
                TEXT1: "Editar Evento",
                TEXT2: "Live",
                TEXT3: "Divulgação",
                TEXT4: "Dashboard",
            },
            CHAT: {
                TEXT1: "Comentar",
            },
            ANALYTICS: {
                TEXT1: "Total de Vendas",
                TEXT2: "Total Pendente",
                TEXT3: "Ingressos Vendidos",
                TEXT4: "Receita",
                TEXT5: "Ingresso",
                TEXT6: "Nome",
                TEXT7: "E-mail",
                TEXT8: "Valor",
                TEXT9: "Status",
                TEXT10: "Data Pedido",
                TEXT11: "Forma de Pagamento",
                BTN1: "Solicitar Retirada",
            },
            MYEVENTS: {
                H1: "Meus Eventos",
                BTN1: "Página do evento",
                BTN2: "Administrar evento",
                BTN3: "Editar evento",
                BTN4: "Administrar Live",
                TEXT1: "horas",
                TEXT2: "Publicado",
                TEXT3: "Não Publicado",
                TEXT4: "Ingresso",
            },
            TICKETS: {
                BTN1: "Adicionar ao calendário",
                BTN2: "Comprar",
                BTN3: "Assistir",
                TEXT1: "Houve um problema na compra do seu ingresso, favor tentar novamente",
            },
            THANKS: {
                TEXT1: "Seu pedido foi realizado com sucesso!",
                TEXT2: "Detalhes do pedido",
                TEXT3: "Número do pedido",
                TEXT4: "1 Ingresso",
                TEXT5: "Taxa de serviço",
                TEXT6: "Total",
                BTN1: "Acessar o evento",
                BTN2: "Confirmação de pagamento em andamento",
                BTN3: "Verifique seu e-mail e clique aqui para atualizar",
                BTN4: "Adicionar ao calendário",
                TEXT7: "Produtor do evento",
                TEXT8: "Algumas maneiras de acessar a live na data e hora que ela começar",
                TEXT9: "Acesse o evento com o link abaixo",
                TEXT10: "Ou adicione o evento ao calendário, botão acima, usando o link que vai estar no convite.",
                TEXT11: "Ou use o link que vai estar em seu e-mail junto da confirmação da compra do ingresso.",
                TEXT12: "Ou acesse devideo.com.br, entre com essa mesma conta e vá em ",
                TEXT13: " e clique ",
                TEXT14: " nesse evento que será mostrado lá junto a todas os ingressos que você comprar.",
            },
            SHARE: {
                TEXT1: "Compartilhe esse evento",
            },
            COUNT: {
                TEXT1: "Evento em",
                TEXT2: "Dias",
                TEXT3: "Horas",
                TEXT4: "Mins",
            },
        },
        EVENT_TYPE: "Online",
        PAGES: {
            EVENT: {
                BUY: "Página de Compra",
            },
            HOME: "Home",
            PAGE_NOT_FOUND: "Página Não Encontrada",
            SIGN_IN: "Entrar",
            SIGN_UP: "Cadastrar",
            WIDGET: "Widget",
        },
    },
    US: {
        ACTION: {
            HOME: "Home",
            CREATE_EVENT: "Create Event",
            MY_EVENTS: "My Events",
            MY_TICKETS: "My Tickets",
            LOGOUT: "Logout",
            SIGNIN: "Sign In",
        },
        COMPANY: {
            NAME: "DeVideo",
            COPYRIGHT: "Copyright 2023",
        },
        ACCOUNT: {
            FORGOT_PASSWORD: "Forgot my password",
            SIGN_IN: "Sign in",
            SIGN_IN_GOOGLE: "Sign in With Google",
            SIGN_UP: "Register",
            RESET_PASSWORD: "Reset password",
        },
        SIGNUP: {
            H1: "Create new account",
            FORM1: "Name",
            FORM2: "E-mail",
            FORM3: "Password",
            CHK: "I've read and agree with the Terms and conditions",
            LINK: "Terms and conditions",
        },
        HOME: {
            HEADER: {},
            MAIN: {
                H1: "Lives with ticket sales",
                TEXT2: "Start broadcasting and monetizing your online events right now",
                TEXT3: "Here your content has the platform it needs and your audience gives you the recognition you deserve.",
                TEXT4: "I want to start doing lives.",
            },
            MIDDLE: {
                H1: "Everything you need for your event",
                TAB1: "Full HD video quality",
                DESC1: "Lives with 1080p Full HD and professional sound to make your event shine",
                TAB2: "Consumer insigths",
                DESC2: "Know your audience with reports on who accessed your live",
                TAB3: "Ticket purchase flexibility",
                DESC3: "Sell tickets via Credit Card, Google Pay, Apple Pay and Pix",
                TAB4: "Chat for interaction with your base",
                DESC4: "Turn your audience into a community and interact with your audience",
                TAB5: "Transfer within 3 days after the event",
                DESC5: "The transfer takes place as soon as there is confirmation the event occurred",
                TAB6: "Support for content creators",
                DESC6: "Real-time support from a team with a lot of streaming experience",
                TAB7: "Producer's page",
                DESC7: "Have all your events published in one place and ready for sale",
                TAB8: "AirPlay and Chromecast",
                DESC8: "Your consumer will watch your live from the comfort of their sofa, using Chromecast or AirPlay",
                TAB9: "Global transmission",
                DESC9: "Content delivery is global, address your audience wherever they are",
                TAB10: "Events Dashboard",
                DESC10: "Dashboard with the most relevant sales and consumption information for your live",
                TAB11: "Multi format",
                DESC11: "Your audience will choose where they want to watch your live, whether mobile, web or tv",
                TAB12: "DIY Plarform",
                DESC12: "Create your live your way, choosing everything shown on the event page",
            },
            FORMATS: {
                H1: "Formats",
                H1DIY: "DIY - Do it yourself",
                H2DIY: "Create your own event and promote it to your audience",
                TEXT1: "Create your event. Choose the images, texts and price of your event",
                TEXT2: "Share it on your channels",
                TEXT3: "Place a ticket sales widget on your website",
                TEXT4: "Monitor sales and chat to your audience before and during the event",
                BTN1: "Create Live",
                H1PAR: "Professional partnership",
                H2PAR: "Get in touch with us and let's start a successful partnership",
                TEXT5: "Large events, with large audiences or high price tickets with special conditions",
                TEXT6: "4K, Multi cameras, Multi stages or video delivery redundancy",
                TEXT7: "Web, mobile or SmartTV App applications customized for your business",
                TEXT8: "Customized Player, Event Page, or Experience",
                BTN2: "Get in touch",
            },
            CONTACTUS: {
                H1: "Got questions? Want to know more?",
                TEXT1: "Name",
                TEXT2: "E-Mail",
                TEXT3: "Phone",
                TEXT4: "Message",
                BTN1: "Send message",
            },
            FAQ: {
                H1: "Frequently asked questions",
                TEXT1: "How does event monetization work?",
                RESP1: "Our platform sells tickets and handles the entire purchase process as well as customer access to your live. After the event is finished, we wait a few days for confirmation that the event has taken place and for any refunds to take place if necessary, once confirmed we transfer 80% of what was charged as a ticket to the event producer and we keep 20% that includes all costs of the streaming platform and ticket collection.",
                TEXT2: "What is a live RTMP?",
                RESP2: "RTMP is the most common protocol used in lives on Youtube, Twitch and etc. It can be used both for content creators using OBS Studio which is a free software or for professional live streams using vMix in a professional setup",
                TEXT3: "Does DeVideo capture the event? Video, sound recording, etc.",
                RESP3: "No, we are dedicated to streaming and we want companies dedicated to capture events to use our platform and expand their activities.",
                TEXT4: "How many people can watch a Live event?",
                RESP4: "On our do-it-yourself platform, where the producer / content creator creates his own live and broadcasts (DIY), we suggest an audience of up to 200,000 people, for larger audiences we suggest contacting us for a commercial partnership.",
                H2: "Journey timeline",
                PROD1: "Producer",
                PROD2: "Create Event",
                PROD3: "Customize event",
                PROD4: "Publish event",
                PROD5: "Share event",
                PROD6: "Present Live",
                PROD7: "Recieve transfer",
                COMP1: "Buyer",
                COMP2: "Buy ticket",
                COMP3: "Watch Live",
            },
            FOOTER: {
                LINK1: "Home",
                LINK2: "Create Event",
                LINK3: "My Tickets",
                LINK4: "Feedback",
                LINK5: "Need Help",
                LINK6: "Terms and Policies",
            },
            LOGGED: {
                H1: "My tickets",
                H2: "My Events",
                TEXT1: "No event found. Use Create Event to create your event.",
                TEXT2: "No ticket purchased.",
            },
        },
        PAGE: {
            ENTRAR: {
                ACCESS: "Sign in",
                EMAIL: "Email",
                PASSWORD: "Password",
                SIGN_IN: "Sign In",
                SIGN_IN_GOOGLE: "Sign In With Google",
                SIGN_UP: "Sign Up",
                FORGOT_PASSWORD: "Forgot Password",
            },
        },
        EVENT: {
            MAIN: {
                H1: "About the event",
                H2: "Event Producer",
                BTN1: "Buy",
                BTN2: "Unavailable",
                BTN3: "All events of",
            },
            ALIAS: {
                BTN1: "View",
            },
            FORM: {
                NAME: "Event name",
                BTN: "Next",
                TMLH1: "Timeline of event creation and publication",
                TML1: "Create event, informing only the name",
                TML2: "Customize with photo, description and ticket value",
                TML3: "Inform Producer details",
                TML4: "Publish the event releasing for sale",
                TML5: "Advertize the event by announcing the sales page",
                TML6: "Present the event at the scheduled date and time",
                TML7: "Receive transfer of tickets sold",
                TMLB1: "Unpublished event",
                TMLB2: "Published event",
            },
            EDIT: {
                H1: "Customize Event",
                H2: "Create an event sales page by customizing the event",
                TEXT1: "Event Poster *",
                TEXT2: "PNG, JPG, GIF up to 10MB",
                TEXT3: "Event details",
                TEXT4: "Description *",
                TEXT5: "Date and time *",
                TEXT6: "Duration - Hours *",
                TEXT7: "Ticket price",
                TEXT8: "Event promo video from Youtube",
                TEXT9: "Producer",
                TEXT10: "The event producer concentrates all events of a particular subject, group or contract, the same account can have several producers, each producer will have its own event page, understand it as a channel where your content will be grouped",
                TEXT11: "Producer name",
                TEXT12: "Alias - devideo.com.br/eventos/alias (without spaces or special characters)",
                TEXT13: "Producer description",
                TEXT14: "Producer poster",
                TEXT15: "Please add a photo bellow",
                TEXT16: "No producer found",
                TEXT17: "All events of a producer will de found at devideo.com.br/*producername*",
                TEXT18: "Use Edit Producer to customize the initial Producer we created for you and if you need to create one more Producer use Create Producer below.",
                BTN1: "Select image file",
                BTN2: "Click here to check if the Youtube link is valid",
                BTN3: "Edit producer",
                BTN4: "Select",
                BTN5: "Selected",
                BTN6: "Create Producer",
                BTN7: "Save and view changes",
                BTN8: "Save and Publish the event",
                BTN9: "Change poster",
                BTN10: "Discard",
                BTN11: "Save",
                COIN: "Currency",
            },
            LIVE: {
                TEXT1: "Server",
                TEXT2: "Transmission Key",
                BTN1: "Copy",
                BTN2: "Consumer area",
            },
            LINKS: {
                H1: "Links to advertize and share your event",
                TEXT1: "Event page",
                TEXT2: "Purchase / Checkout page",
                TEXT3: "Widget to add on your page",
                BTN1: "Copy",
                BTN2: "View",
            },
            LEFTBAR: {
                TEXT1: "Edit Event",
                TEXT2: "Live",
                TEXT3: "Advertize",
                TEXT4: "Dashboard",
            },
            CHAT: {
                TEXT1: "Comment",
            },
            ANALYTICS: {
                TEXT1: "Sales total",
                TEXT2: "Pending Total",
                TEXT3: "Tickets sold",
                TEXT4: "Revenue",
                TEXT5: "Ticket",
                TEXT6: "Name",
                TEXT7: "E-mail",
                TEXT8: "Amount",
                TEXT9: "Status",
                TEXT10: "Purchase date",
                TEXT11: "Payment method",
                BTN1: "Request transfer",
            },
            MYEVENTS: {
                H1: "My Events",
                BTN1: "Event page",
                BTN2: "Administrate event",
                BTN3: "Edit Event",
                BTN4: "Administrate Live",
                TEXT1: "hours",
                TEXT2: "Published event",
                TEXT3: "Unpublished event",
                TEXT4: "Ticket",
            },
            TICKETS: {
                BTN1: "Add to calendar",
                BTN2: "Buy ticket",
                BTN3: "Watch",
                TEXT1: "An error ocurred on this ticket purchase, please try again",
            },
            THANKS: {
                TEXT1: "Your ticket purchase was successful",
                TEXT2: "Order details",
                TEXT3: "Order number",
                TEXT4: "1 Ticket",
                TEXT5: "Service fee",
                TEXT6: "Total",
                BTN1: "Watch event",
                BTN2: "Waiting for payment confirmation",
                BTN3: "Please check your e-mail",
                BTN4: "Add to calendar",
                TEXT7: "Event producer",
                TEXT8: "Here are some ways to watch the event when it starts",
                TEXT9: "Access the event link",
                TEXT10: "Or add the event to your Calendar and click on the link placed in the invite.",
                TEXT11: "Or use the link on the e-mail of purchase confirmation",
                TEXT12: "Or access devideo.com.br, sign in to this account and select ",
                TEXT13: " and click ",
                TEXT14: " on this event",
            },
            SHARE: {
                TEXT1: "Share this event",
            },
            COUNT: {
                TEXT1: "Event in",
                TEXT2: "Days",
                TEXT3: "Hours",
                TEXT4: "Mins",
            },
        },
        EVENT_TYPE: "Online",
        PAGES: {
            EVENT: {
                BUY: "Event purchase page",
            },
            HOME: "Home",
            PAGE_NOT_FOUND: "Page not found",
            SIGN_IN: "SignIn",
            SIGN_UP: "SignUp",
            WIDGET: "Widget",
        },
    },
    ES: {
        ACTION: {
            HOME: "Inicio",
            CREATE_EVENT: "Crear evento",
            MY_EVENTS: "Mis eventos",
            MY_TICKETS: "Mis entradas",
            LOGOUT: "Cerrar sesión",
            SIGNIN: "Iniciar sesión",
        },
        COMPANY: {
            NAME: "DeVideo",
            COPYRIGHT: "Derechos de autor 2023",
        },
        ACCOUNT: {
            FORGOT_PASSWORD: "Olvidé mi contraseña",
            SIGN_IN: "Iniciar sesión",
            SIGN_IN_GOOGLE: "Iniciar sesión con Google",
            SIGN_UP: "Registrarse",
            RESET_PASSWORD: "Restablecer contraseña",
        },
        SIGNUP: {
            H1: "Crear nueva cuenta",
            FORM1: "Nombre",
            FORM2: "Correo electrónico",
            FORM3: "Contraseña",
            CHK: "He leído y acepto los términos y condiciones",
            LINK: "Términos y condiciones",
        },
        HOME: {
            HEADER: {},
            MAIN: {
                H1: "Lives con venta de entradas",
                TEXT2: "Comience a transmitir y monetizar sus eventos en línea ahora mismo",
                TEXT3: "Aquí, su contenido tiene la plataforma que necesita y su audiencia le brinda el reconocimiento que merece.",
                TEXT4: "Quiero empezar a hacer eventos.",
            },
            MIDDLE: {
                H1: "Todo lo que necesitas para tu evento",
                TAB1: "Calidad de video Full HD",
                DESC1: "Lives con 1080p Full HD y sonido profesional para hacer brillar tu evento",
                TAB2: "Información del consumidor",
                DESC2: "Conozca a su audiencia con informes sobre quién accedió a su Live",
                TAB3: "Flexibilidad de compra de boletos",
                DESC3: "Venda boletos a través de tarjeta de crédito, Google Pay, Apple Pay y Pix",
                TAB4: "Chat para interactuar con tu base",
                DESC4: "Convierte a tu audiencia en una comunidad e interactúa con tu audiencia.",
                TAB5: "Transferencia dentro de los 3 días posteriores al evento",
                DESC5: "La transferencia se realiza tan pronto como se confirma que ocurrió el evento.",
                TAB6: "Soporte para creadores de contenido",
                DESC6: "Soporte en tiempo real de un equipo con mucha experiencia en transmisión.",
                TAB7: "Página del productor",
                DESC7: "Tenga todos sus eventos publicados en un solo lugar y listos para la venta.",
                TAB8: "AirPlay y Chromecast",
                DESC8: "Su consumidor verá su Live desde la comodidad de su sofá, usando Chromecast o AirPlay.",
                TAB9: "Transmisión global",
                DESC9: "La entrega de contenido es global, diríjase a su audiencia dondequiera que estén.",
                TAB10: "Panel de eventos",
                DESC10: "Panel con la información de ventas y consumo más relevante para su Live.",
                TAB11: "Multi formato",
                DESC11: "Su audiencia elegirá dónde quiere ver su Live, ya sea móvil, web o televisión",
                TAB12: "Plataforma DIY",
                DESC12: "Crea tu evento a tu manera, eligiendo todo lo que se muestra en la página del evento",
            },
            FORMATS: {
                H1: "Formatos",
                H1DIY: "Hazlo tú mismo",
                H2DIY: "Crea tu propio evento y promuévelo a tu audiencia",
                TEXT1: "Crea tu evento. Elige las imágenes, textos y precio de tu evento",
                TEXT2: "Compártelo en tus canales",
                TEXT3: "Coloque un widget de venta de entradas en su sitio web",
                TEXT4: "Monitorea las ventas y chatea con tu audiencia antes y durante el evento",
                BTN1: "Crear Live",
                H1PAR: "Asociación profesional",
                H2PAR: "Ponte en contacto con nosotros y comencemos una asociación exitosa",
                TEXT5: "Grandes eventos, con grandes audiencias o entradas de alto precio con condiciones especiales",
                TEXT6: "4K, cámaras múltiples, etapas múltiples o redundancia en la entrega de video",
                TEXT7: "Aplicaciones web, móviles o SmartTV personalizadas para su negocio",
                TEXT8: "Reproductor personalizado, página del evento o experiencia",
                BTN2: "Ponte en contacto",
            },
            CONTACTUS: {
                H1: "¿Tienes preguntas? ¿Quieres saber más?",
                TEXT1: "Nombre",
                TEXT2: "Correo electrónico",
                TEXT3: "Teléfono",
                TEXT4: "Mensaje",
                BTN1: "Enviar mensaje",
            },
            FAQ: {
                H1: "Preguntas frecuentes",
                TEXT1: "¿Cómo funciona la monetización de eventos?",
                RESP1: "Nuestra plataforma vende entradas y maneja todo el proceso de compra, así como el acceso del cliente a su evento en vivo. Después de que el evento haya finalizado, esperamos unos días para confirmar que el evento ha tenido lugar y para que se realicen los reembolsos si es necesario, una vez confirmado, transferimos el 80% de lo que se cobró como entrada al productor del evento y nos quedamos con el 20% que incluye todos los costos de la plataforma de transmisión y la recolección de entradas.",
                TEXT2: "¿Qué es un RTMP en vivo?",
                RESP2: "RTMP es el protocolo más común utilizado en transmisiones en vivo en Youtube, Twitch, etc. Se puede utilizar tanto para creadores de contenido que utilizan OBS Studio, que es un software gratuito, como para transmisiones en vivo profesionales utilizando vMix en una configuración profesional",
                TEXT3: "¿DeVideo captura el evento? Grabación de video, sonido, etc.",
                RESP3: "No, nos dedicamos a la transmisión y queremos que las empresas dedicadas a la captura de eventos utilicen nuestra plataforma y amplíen sus actividades.",
                TEXT4: "¿Cuántas personas pueden ver un evento en vivo?",
                RESP4: "En nuestra plataforma hazlo tú mismo, donde el productor / creador de contenido crea su propio evento en vivo y lo transmite (DIY), sugerimos una audiencia de hasta 200,000 personas, para audiencias más grandes sugerimos contactarnos para una asociación comercial.",
                H2: "Cronología del viaje",
                PROD1: "Productor",
                PROD2: "Crear evento",
                PROD3: "Personalizar evento",
                PROD4: "Publicar evento",
                PROD5: "Compartir evento",
                PROD6: "Presentar en vivo",
                PROD7: "Recibir transferencia",
                COMP1: "Comprador",
                COMP2: "Comprar boleto",
                COMP3: "Ver en vivo",
            },
            FOOTER: {
                LINK1: "Inicio",
                LINK2: "Crear evento",
                LINK3: "Mis entradas",
                LINK4: "Comentarios",
                LINK5: "Necesito ayuda",
                LINK6: "Términos y políticas",
            },
            LOGGED: {
                H1: "Mis entradas",
                H2: "Mis eventos",
                TEXT1: "No se encontró ningún evento. Utilice Crear evento para crear su evento.",
                TEXT2: "No se compró ningún boleto.",
            },
        },
        PAGE: {
            ENTRAR: {
                ACCESS: "Iniciar sesión",
                EMAIL: "Correo electrónico",
                PASSWORD: "Contraseña",
                SIGN_IN: "Iniciar sesión",
                SIGN_IN_GOOGLE: "Iniciar sesión con Google",
                SIGN_UP: "Registrarse",
                FORGOT_PASSWORD: "Olvidé mi contraseña",
            },
        },
        EVENT: {
            MAIN: {
                H1: "Sobre el evento",
                H2: "Productor del evento",
                BTN1: "Comprar",
                BTN2: "No disponible",
                BTN3: "Todos los eventos de",
            },
            ALIAS: {
                BTN1: "Ver",
            },
            FORM: {
                NAME: "Nombre del evento",
                BTN: "Siguiente",
                TMLH1: "Cronograma de creación y publicación del evento",
                TML1: "Crear evento, informando solo el nombre",
                TML2: "Personalizar con foto, descripción y valor del boleto",
                TML3: "Informar detalles del productor",
                TML4: "Publicar el evento liberando para la venta",
                TML5: "Anunciar el evento anunciando la página de ventas",
                TML6: "Presentar el evento en la fecha y hora programadas",
                TML7: "Recibir transferencia de boletos vendidos",
                TMLB1: "Evento no publicado",
                TMLB2: "Evento publicado",
            },
            EDIT: {
                H1: "Personalizar evento",
                H2: "Crea una página de ventas de eventos personalizando el evento",
                TEXT1: "Cartel del evento *",
                TEXT2: "PNG, JPG, GIF hasta 10MB",
                TEXT3: "Detalles del evento",
                TEXT4: "Descripción *",
                TEXT5: "Fecha y hora *",
                TEXT6: "Duración - Horas *",
                TEXT7: "Precio del boleto",
                TEXT8: "Video promocional del evento de Youtube",
                TEXT9: "Productor",
                TEXT10: "El productor del evento concentra todos los eventos de un tema, grupo o contrato en particular, la misma cuenta puede tener varios productores, cada productor tendrá su propia página de eventos, entiéndelo como un canal donde se agrupará tu contenido",
                TEXT11: "Nombre del productor",
                TEXT12: "Alias - devideo.com.br/eventos/alias (sin espacios ni caracteres especiales)",
                TEXT13: "Descripción del productor",
                TEXT14: "Cartel del productor",
                TEXT15: "Por favor agregue una foto a continuación",
                TEXT16: "No se encontró ningún productor",
                TEXT17: "Todos los eventos de un productor se encontrarán en devideo.com.br/*nombre del productor*",
                TEXT18: "Utilice Editar Productor para personalizar el Productor inicial que creamos para usted y si necesita crear un Productor más, utilice Crear Productor a continuación.",
                BTN1: "Seleccionar archivo de imagen",
                BTN2: "Haga clic aquí para verificar si el enlace de Youtube es válido",
                BTN3: "Editar productor",
                BTN4: "Seleccionar",
                BTN5: "Seleccionado",
                BTN6: "Crear Productor",
                BTN7: "Guardar y ver cambios",
                BTN8: "Guardar y publicar el evento",
                BTN9: "Cambiar cartel",
                BTN10: "Descartar",
                BTN11: "Guardar",
                COIN: "Moneda",
            },
            LIVE: {
                TEXT1: "Servidor",
                TEXT2: "Clave de transmisión",
                BTN1: "Copiar",
                BTN2: "Área del consumidor",
            },
            LINKS: {
                H1: "Enlaces para anunciar y compartir su evento",
                TEXT1: "Página del evento",
                TEXT2: "Página de compra / pago",
                TEXT3: "Widget para agregar en su página",
                BTN1: "Copiar",
                BTN2: "Ver",
            },
            LEFTBAR: {
                TEXT1: "Editar evento",
                TEXT2: "En vivo",
                TEXT3: "Anunciar",
                TEXT4: "Panel de control",
            },
            CHAT: {
                TEXT1: "Comentar",
            },
            ANALYTICS: {
                TEXT1: "Total de ventas",
                TEXT2: "Total pendiente",
                TEXT3: "Boletos vendidos",
                TEXT4: "Ingresos",
                TEXT5: "Boleto",
                TEXT6: "Nombre",
                TEXT7: "Correo electrónico",
                TEXT8: "Cantidad",
                TEXT9: "Estado",
                TEXT10: "Fecha de compra",
                TEXT11: "Método de pago",
                BTN1: "Solicitar transferencia",
            },
            MYEVENTS: {
                H1: "Mis eventos",
                BTN1: "Página del evento",
                BTN2: "Administrar evento",
                BTN3: "Editar evento",
                BTN4: "Administrar en vivo",
                TEXT1: "horas",
                TEXT2: "Evento publicado",
                TEXT3: "Evento no publicado",
                TEXT4: "Boleto",
            },
            TICKETS: {
                BTN1: "Agregar al calendario",
                BTN2: "Comprar boleto",
                BTN3: "Ver",
                TEXT1: "Ocurrió un error en la compra de este boleto, por favor intente de nuevo",
            },
            THANKS: {
                TEXT1: "Su compra de boletos fue exitosa",
                TEXT2: "Detalles del pedido",
                TEXT3: "Número de pedido",
                TEXT4: "1 Boleto",
                TEXT5: "Tarifa de servicio",
                TEXT6: "Total",
                BTN1: "Ver evento",
                BTN2: "Esperando confirmación de pago",
                BTN3: "Por favor revise su correo electrónico",
                BTN4: "Agregar al calendario",
                TEXT7: "Productor del evento",
                TEXT8: "Aquí hay algunas formas de ver el evento cuando comience",
                TEXT9: "Acceda al enlace del evento",
                TEXT10: "O agregue el evento a su Calendario y haga clic en el enlace colocado en la invitación.",
                TEXT11: "O use el enlace en el correo electrónico de confirmación de compra",
                TEXT12: "O acceda a devideo.com.br, inicie sesión en esta cuenta y seleccione ",
                TEXT13: " y haga clic ",
                TEXT14: " en este evento",
            },
            SHARE: {
                TEXT1: "Comparte este evento",
            },
            COUNT: {
                TEXT1: "Evento en",
                TEXT2: "Días",
                TEXT3: "Horas",
                TEXT4: "Minutos",
            },
        },
        EVENT_TYPE: "En línea",
        PAGES: {
            EVENT: {
                BUY: "Página de compra del evento",
            },
            HOME: "Inicio",
            PAGE_NOT_FOUND: "Página no encontrada",
            SIGN_IN: "Iniciar sesión",
            SIGN_UP: "Registrarse",
            WIDGET: "Widget",
        },
    },
};
exports.PAGE = {
    BR: {
        ENTRAR: {
            ACCESS: "Entrar",
            EMAIL: "Email",
            PASSWORD: "Senha",
            SIGN_IN: "Entrar",
            SIGN_IN_GOOGLE: "Entrar com Google",
            SIGN_UP: "Cadastrar",
            FORGOT_PASSWORD: "Esqueci minha senha",
        },
        CreateEventPage: "Produtor - Criar Evento",
        EditEventPage: "Produtor - Editar Evento",
        MyEventsPage: "Produtor - Meus Eventos",
        EventPage: "Consumidor - Evento",
        ThanksPage: "Consumidor - Obrigado",
        EventPayPage: "Consumidor - Página de Pagamento",
        PaymentStatusPage: "Consumidor - Status de Pagamento",
        AnalyticsPage: "Produtor - Analytics",
        LivePage: "Produtor - Live",
        SignInPage: "Consumidor - Entrar",
        SignUpPage: "Consumidor - Cadastrar",
        SharePage: "Produtor - Compartilhamento",
        TicketPage: "Consumidor - Ingresso",
        EventBuyPage: "Consumidor - Compra",
        HomePage: "Página Inicial",
        LoginPage: "Entrar",
        Terms: "Termos",
        NotFoundPage: "Página não encontrada",
        ResetPage: "Redefinir senha",
        WidgetPage: "Widget",
        EventConsumer: "Evento",
        WatchPage: "Assistir",
    },
    US: {
        ENTRAR: {
            ACCESS: "Signin",
            EMAIL: "Email",
            PASSWORD: "Password",
            SIGN_IN: "Signin",
            SIGN_IN_GOOGLE: "Signin with Google",
            SIGN_UP: "Register",
            FORGOT_PASSWORD: "Forgot my password",
        },
        CreateEventPage: "Producer - Create Event",
        EditEventPage: "Producer - Edit Event",
        MyEventsPage: "Producer - My Events",
        EventPage: "Consumer - Event",
        ThanksPage: "Consumer - Thanks",
        EventPayPage: "Consumer - Checkout",
        PaymentStatusPage: "Consumer - Payment status",
        AnalyticsPage: "Producer - Analytics",
        LivePage: "Producer - Live",
        SignInPage: "Consumer - Signin",
        SignUpPage: "Consumer - Register",
        SharePage: "Producer - Share",
        TicketPage: "Consumer - Ticket",
        EventBuyPage: "Consumer - Purchase",
        HomePage: "Home",
        LoginPage: "Signin",
        Terms: "Terms",
        NotFoundPage: "Page not found",
        ResetPage: "Reset password",
        WidgetPage: "Widget",
        EventConsumer: "Event",
        WatchPage: "Watch",
    },
    ES: {
        ENTRAR: {
            ACCESS: "Iniciar sesión",
            EMAIL: "Correo electrónico",
            PASSWORD: "Contraseña",
            SIGN_IN: "Iniciar sesión",
            SIGN_IN_GOOGLE: "Iniciar sesión con Google",
            SIGN_UP: "Registrarse",
            FORGOT_PASSWORD: "Olvidé mi contraseña",
        },
        CreateEventPage: "Productor - Crear evento",
        EditEventPage: "Productor - Editar evento",
        MyEventsPage: "Productor - Mis eventos",
        EventPage: "Consumidor - Evento",
        ThanksPage: "Consumidor - Gracias",
        EventPayPage: "Consumidor - Pagar",
        PaymentStatusPage: "Consumidor - Estado del pago",
        AnalyticsPage: "Productor - Análisis",
        LivePage: "Productor - En vivo",
        SignInPage: "Consumidor - Iniciar sesión",
        SignUpPage: "Consumidor - Registrarse",
        SharePage: "Productor - Compartir",
        TicketPage: "Consumidor - Boleto",
        EventBuyPage: "Consumidor - Compra",
        HomePage: "Inicio",
        LoginPage: "Iniciar sesión",
        Terms: "Términos y condiciones",
        NotFoundPage: "Página no encontrada",
        ResetPage: "Restablecer contraseña",
        WidgetPage: "Widget",
        EventConsumer: "Evento",
        WatchPage: "Ver en vivo",
    },
};
