"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bitmovin_player_ui_1 = require("bitmovin-player-ui");
var BitmovinUI = function (player, config, isLive) {
    var vodUi = function () {
        var controlBar = new bitmovin_player_ui_1.ControlBar({
            components: [
                new bitmovin_player_ui_1.Container({
                    components: [
                        new bitmovin_player_ui_1.PlaybackTimeLabel({ timeLabelMode: bitmovin_player_ui_1.PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true }),
                        new bitmovin_player_ui_1.SeekBar({ label: new bitmovin_player_ui_1.SeekBarLabel() }),
                        new bitmovin_player_ui_1.PlaybackTimeLabel({ timeLabelMode: bitmovin_player_ui_1.PlaybackTimeLabelMode.TotalTime, cssClasses: ["text-right"] }),
                    ],
                    cssClasses: ["controlbar-top"],
                }),
                new bitmovin_player_ui_1.Container({
                    components: [
                        new bitmovin_player_ui_1.PlaybackToggleButton(),
                        new bitmovin_player_ui_1.VolumeToggleButton(),
                        new bitmovin_player_ui_1.VolumeSlider(),
                        new bitmovin_player_ui_1.Spacer(),
                        new bitmovin_player_ui_1.PictureInPictureToggleButton(),
                        new bitmovin_player_ui_1.AirPlayToggleButton(),
                        new bitmovin_player_ui_1.CastToggleButton(),
                        new bitmovin_player_ui_1.FullscreenToggleButton(),
                    ],
                    cssClasses: ["controlbar-bottom"],
                }),
            ],
        });
        return new bitmovin_player_ui_1.UIContainer({
            components: [
                new bitmovin_player_ui_1.BufferingOverlay(),
                new bitmovin_player_ui_1.PlaybackToggleOverlay(),
                new bitmovin_player_ui_1.CastStatusOverlay(),
                controlBar,
                new bitmovin_player_ui_1.TitleBar(),
                new bitmovin_player_ui_1.ErrorMessageOverlay(),
            ],
            hideDelay: 2000,
            hidePlayerStateExceptions: [
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Prepared,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Paused,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Finished,
            ],
        });
    };
    var liveUi = function () {
        var controlBar = new bitmovin_player_ui_1.ControlBar({
            components: [
                new bitmovin_player_ui_1.Container({
                    components: [
                        new bitmovin_player_ui_1.PlaybackTimeLabel({ timeLabelMode: bitmovin_player_ui_1.PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true }),
                        new bitmovin_player_ui_1.PlaybackToggleButton(),
                        new bitmovin_player_ui_1.VolumeToggleButton(),
                        new bitmovin_player_ui_1.VolumeSlider(),
                        new bitmovin_player_ui_1.Spacer(),
                        new bitmovin_player_ui_1.PictureInPictureToggleButton(),
                        new bitmovin_player_ui_1.AirPlayToggleButton(),
                        new bitmovin_player_ui_1.CastToggleButton(),
                        new bitmovin_player_ui_1.FullscreenToggleButton(),
                    ],
                    cssClasses: ["controlbar-bottom"],
                }),
            ],
        });
        return new bitmovin_player_ui_1.UIContainer({
            components: [
                new bitmovin_player_ui_1.BufferingOverlay(),
                new bitmovin_player_ui_1.PlaybackToggleOverlay(),
                new bitmovin_player_ui_1.CastStatusOverlay(),
                controlBar,
                new bitmovin_player_ui_1.TitleBar(),
                new bitmovin_player_ui_1.ErrorMessageOverlay(),
            ],
            hideDelay: 2000,
            hidePlayerStateExceptions: [
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Prepared,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Paused,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Finished,
            ],
        });
    };
    var smallScreenUi = function () {
        var controlBar = new bitmovin_player_ui_1.ControlBar({
            components: [
                new bitmovin_player_ui_1.Container({
                    components: [new bitmovin_player_ui_1.SeekBar()],
                    cssClasses: ["controlbar-top"],
                }),
            ],
        });
        return new bitmovin_player_ui_1.UIContainer({
            components: [
                new bitmovin_player_ui_1.PlaybackToggleOverlay(),
                controlBar,
                new bitmovin_player_ui_1.TitleBar({
                    components: [new bitmovin_player_ui_1.VolumeToggleButton(), new bitmovin_player_ui_1.Spacer()],
                }),
                new bitmovin_player_ui_1.PictureInPictureToggleButton(),
            ],
            cssClasses: ["ui-skin-smallscreen"],
            hideDelay: 2000,
            hidePlayerStateExceptions: [
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Prepared,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Paused,
                bitmovin_player_ui_1.PlayerUtils.PlayerState.Finished,
            ],
        });
    };
    var uiManager = function () {
        var smallScreenSwitchWidth = 600;
        return new bitmovin_player_ui_1.UIManager(player, [
            {
                ui: smallScreenUi(),
                condition: function (context) {
                    return (!context.isAd &&
                        !context.adRequiresUi &&
                        context.isMobile &&
                        context.documentWidth < smallScreenSwitchWidth);
                },
            },
            {
                ui: isLive ? liveUi() : vodUi(),
                condition: function (context) {
                    return !context.isAd && !context.adRequiresUi;
                },
            },
        ], config);
    };
    return { uiManager: uiManager };
};
exports.default = BitmovinUI;
