"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var icons_1 = require("@assets/icons");
var useAuth_1 = require("@hooks/useAuth");
var useLanguage_1 = require("@hooks/useLanguage");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var locale_1 = require("src/locale");
var InputMessage = function (_a) {
    var socket = _a.socket;
    var user = (0, useAuth_1.useAuth)().user;
    var hash = (0, react_router_dom_1.useParams)().hash;
    var _b = (0, react_1.useState)(""), message = _b[0], setMessage = _b[1];
    var language = (0, useLanguage_1.useLanguage)().language;
    var handleSendMessage = function (e) {
        e.preventDefault();
        if (message.trim()) {
            socket.emit("message", {
                isAdmin: true,
                hash: hash,
                message: message,
                user: {
                    id: user.accountId,
                    name: user.name,
                },
            });
        }
        setMessage("");
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "chat__footer" }, { children: (0, jsx_runtime_1.jsxs)("form", __assign({ className: "flex", onSubmit: handleSendMessage }, { children: [(0, jsx_runtime_1.jsx)("input", { className: "flex flex-1 rounded-xl bg-slate-600  text-sm text-slate-300 focus:border-transparent focus:outline-none focus:ring-2", placeholder: locale_1.LOCALE[language].EVENT.CHAT.TEXT1, value: message, maxLength: 254, onChange: function (e) { return setMessage(e.target.value); } }), (0, jsx_runtime_1.jsx)("button", __assign({ disabled: !socket.connected && !message, className: "rounded-xl bg-slate-600 px-4", onClick: function (e) { return handleSendMessage(e); } }, { children: (0, jsx_runtime_1.jsx)(icons_1.myIcon.react.FontAwesomeIcon, { className: "text-base text-slate-300", icon: icons_1.myIcon.solid.faPaperPlane }) }))] })) })));
};
exports.default = InputMessage;
