"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ga_1 = require("@config/ga");
var react_1 = require("react");
var useDocumentTitle = function (title, prevailOnUnmount) {
    if (prevailOnUnmount === void 0) { prevailOnUnmount = false; }
    var defaultTitle = (0, react_1.useRef)(document.title);
    (0, react_1.useEffect)(function () {
        document.title = title;
    }, [title]);
    (0, react_1.useEffect)(function () { return function () {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
            (0, ga_1.sendGA)({ event: "pageView", pageView: location.href, pageTitle: defaultTitle.current });
        }
    }; }, []);
};
exports.default = useDocumentTitle;
