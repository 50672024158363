"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_router_dom_2 = require("react-router-dom");
var icons_1 = require("@assets/icons");
var locale_1 = require("src/locale");
var useLanguage_1 = require("@hooks/useLanguage");
var LeftBar = function () {
    var language = (0, useLanguage_1.useLanguage)().language;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var hash = (0, react_router_dom_2.useParams)().hash;
    var rotasLateral = [
        {
            id: "",
            name: locale_1.LOCALE[language].EVENT.LEFTBAR.TEXT1,
            to: "/produtor/evento/".concat(hash, "/editar"),
        },
        {
            id: "",
            name: locale_1.LOCALE[language].EVENT.LEFTBAR.TEXT2,
            to: "/produtor/evento/".concat(hash, "/assistir"),
        },
        {
            id: "",
            name: locale_1.LOCALE[language].EVENT.LEFTBAR.TEXT3,
            to: "/produtor/evento/".concat(hash, "/divulgar"),
        },
        {
            id: "",
            name: locale_1.LOCALE[language].EVENT.LEFTBAR.TEXT4,
            to: "/produtor/evento/".concat(hash, "/analytics"),
        },
    ];
    var Accordion = function (_a) {
        var title = _a.title, content = _a.content;
        var _b = (0, react_1.useState)(false), isActive = _b[0], setIsActive = _b[1];
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full pb-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center justify-end ", onClick: function () { return setIsActive(!isActive); } }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center" }, { children: title })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-12 items-center justify-end" }, { children: isActive ? ((0, jsx_runtime_1.jsx)(icons_1.myIcon.react.FontAwesomeIcon, { className: "h-4 w-4 rounded-full bg-slate-500 p-2 text-base text-white", icon: icons_1.myIcon.solid.faChevronUp })) : ((0, jsx_runtime_1.jsx)(icons_1.myIcon.react.FontAwesomeIcon, { className: "h-4 w-4 rounded-full bg-slate-500 p-2 text-base text-white", icon: icons_1.myIcon.solid.faChevronDown })) }))] })), isActive && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-full items-center justify-center rounded-xl pt-4" }, { children: (0, jsx_runtime_1.jsx)("ul", __assign({ className: "flex h-full w-full flex-col gap-4 rounded-xl bg-slate-800 px-4 py-6 text-slate-200 sm:w-80" }, { children: rotasLateral.map(function (_li, _i) {
                            return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, __assign({ to: _li.to, className: _li.to === pathname ? "font-bold" : "" }, { children: (0, jsx_runtime_1.jsx)("li", __assign({ className: _li.to === pathname
                                        ? "my-1  w-full rounded-md border border-slate-400 bg-slate-700 p-2 px-6"
                                        : "my-1 w-full rounded-md border border-slate-400 bg-slate-900 p-2 px-6" }, { children: _li.name }), _i) }), _i));
                        }) })) })))] })));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-slate-900 sm:hidden" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center justify-end px-7 pt-8" }, { children: (0, jsx_runtime_1.jsx)(Accordion, { title: "Menu", content: "" }) })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "invisible flex h-0 w-60 flex-col items-center justify-center bg-slate-900 sm:visible sm:h-full sm:w-full sm:p-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-full flex-row justify-between rounded-t-xl bg-slate-900 p-2 text-base font-bold text-slate-300 sm:max-w-sm sm:px-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-1 items-center justify-start" }, { children: "Menu" })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex h-8 w-8 items-center justify-center rounded-lg" }, { children: (0, jsx_runtime_1.jsx)(icons_1.myIcon.react.FontAwesomeIcon, { className: "text-xl text-slate-300", icon: icons_1.myIcon.solid.faChevronDown }) }))] })), (0, jsx_runtime_1.jsx)("ul", __assign({ className: "flex h-full w-full flex-col gap-4 bg-slate-800 px-4 py-6 text-slate-200 sm:w-80" }, { children: rotasLateral.map(function (_li, _i) {
                            return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, __assign({ to: _li.to, className: _li.to === pathname ? "font-bold" : "" }, { children: (0, jsx_runtime_1.jsx)("li", __assign({ className: _li.to === pathname
                                        ? "my-1  w-full rounded-md border border-slate-400 bg-slate-700 p-2 px-6"
                                        : "my-1 w-full rounded-md border border-slate-400 bg-slate-900 p-2 px-6" }, { children: _li.name }), _i) }), _i));
                        }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-full flex-row justify-between rounded-b-xl bg-slate-800 p-2 text-base font-bold text-slate-300 opacity-80 sm:max-w-sm sm:px-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-1 items-center justify-start text-slate-800" }, { children: "." })) }))] }))] })));
};
exports.default = LeftBar;
