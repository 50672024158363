"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageContextProvider = exports.LanguageContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var LanguageContext_d_1 = require("./LanguageContext.d");
var LanguageContext = (0, react_1.createContext)({});
exports.LanguageContext = LanguageContext;
var LanguageContextProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(localStorage.getItem("language") || navigator.language !== "pt-BR" ? "US" : "BR"), language = _b[0], setLanguage = _b[1];
    var _c = (0, react_1.useState)({
        code: LanguageContext_d_1.TRANSLATE.BR.code,
        image: LanguageContext_d_1.TRANSLATE.BR.image,
        language: LanguageContext_d_1.TRANSLATE.BR.language,
        name: LanguageContext_d_1.TRANSLATE.BR.name,
    }), languageInfo = _c[0], setLanguageInfo = _c[1];
    (0, react_1.useEffect)(function () {
        setLanguageInfo({
            code: LanguageContext_d_1.TRANSLATE[language].code || LanguageContext_d_1.TRANSLATE["BR"].code,
            image: LanguageContext_d_1.TRANSLATE[language].image || LanguageContext_d_1.TRANSLATE["BR"].image,
            language: LanguageContext_d_1.TRANSLATE[language].language || LanguageContext_d_1.TRANSLATE["BR"].language,
            name: LanguageContext_d_1.TRANSLATE[language].name || LanguageContext_d_1.TRANSLATE["BR"].name,
        });
    }, [language]);
    (0, react_1.useEffect)(function () {
        localStorage.setItem("language", languageInfo.language);
    }, [languageInfo]);
    return ((0, jsx_runtime_1.jsx)(LanguageContext.Provider, __assign({ value: {
            language: language,
            setLanguage: setLanguage,
            languageInfo: languageInfo,
            setLanguageInfo: setLanguageInfo,
        } }, { children: children })));
};
exports.LanguageContextProvider = LanguageContextProvider;
