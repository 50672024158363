"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeLocalStorage = exports.getLocalStorage = exports.setLocalStorage = void 0;
var setLocalStorage = function (key, value) {
    return localStorage.setItem(key, value);
};
exports.setLocalStorage = setLocalStorage;
var getLocalStorage = function (key) {
    return localStorage.getItem(key) || undefined;
};
exports.getLocalStorage = getLocalStorage;
var removeLocalStorage = function (key) {
    return localStorage.removeItem(key);
};
exports.removeLocalStorage = removeLocalStorage;
