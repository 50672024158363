"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyInput = void 0;
var copyInput = function (elementId) {
    var copyText = document.getElementById(elementId);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    return navigator.clipboard.writeText(copyText.value);
};
exports.copyInput = copyInput;
