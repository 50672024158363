"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthContextProvider = exports.AuthContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var storage_1 = require("@utils/storage");
var react_router_1 = require("react-router");
var AuthContext = (0, react_1.createContext)({});
exports.AuthContext = AuthContext;
var AuthContextProvider = function (_a) {
    var children = _a.children;
    var navigate = (0, react_router_1.useNavigate)();
    var _b = (0, react_1.useState)((0, storage_1.getLocalStorage)("Token")), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = (0, react_1.useState)({
        accountId: (0, storage_1.getLocalStorage)("AccountId") || "",
        producerId: (0, storage_1.getLocalStorage)("ProducerId") || "",
        email: (0, storage_1.getLocalStorage)("Email") || "",
        name: (0, storage_1.getLocalStorage)("Name") || "",
        image: (0, storage_1.getLocalStorage)("Image") || "",
        term: (0, storage_1.getLocalStorage)("Term") || "",
        token: (0, storage_1.getLocalStorage)("Token") || "",
    }), user = _c[0], setUser = _c[1];
    var signOut = function () {
        localStorage.removeItem("AccountId");
        localStorage.removeItem("ProducerId");
        localStorage.removeItem("Email");
        localStorage.removeItem("Name");
        localStorage.removeItem("Image");
        localStorage.removeItem("Term");
        localStorage.removeItem("Token");
        setUser(null);
        navigate("/");
        navigate(0);
    };
    var signIn = function (_a) {
        var accountId = _a.accountId, producerId = _a.producerId, email = _a.email, name = _a.name, image = _a.image, term = _a.term, token = _a.token;
        setUser({
            accountId: accountId,
            producerId: producerId,
            email: email,
            name: name,
            image: image,
            term: term,
            token: token,
        });
        (0, storage_1.setLocalStorage)("AccountId", accountId);
        (0, storage_1.setLocalStorage)("ProducerId", producerId);
        (0, storage_1.setLocalStorage)("Email", email);
        (0, storage_1.setLocalStorage)("Name", name);
        (0, storage_1.setLocalStorage)("Image", image);
        (0, storage_1.setLocalStorage)("Term", term);
        (0, storage_1.setLocalStorage)("Token", token);
        navigate(0);
    };
    return ((0, jsx_runtime_1.jsx)(AuthContext.Provider, __assign({ value: {
            isAuthenticated: isAuthenticated,
            setIsAuthenticated: setIsAuthenticated,
            user: user,
            setUser: setUser,
            signOut: signOut,
            signIn: signIn,
        } }, { children: children })));
};
exports.AuthContextProvider = AuthContextProvider;
