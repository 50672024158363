"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalContextProvider = exports.ModalContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ModalContext = (0, react_1.createContext)({});
exports.ModalContext = ModalContext;
var ModalContextProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)({ enable: false, message: "", title: "" }), showModal = _b[0], setShowModal = _b[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        window.addEventListener("redirect", function (e) {
            navigate("/");
        });
        window.addEventListener("modalListener", function (e) {
            setShowModal({ enable: true, template: e.detail });
        });
        return function () {
            window.removeEventListener("modalListener", function (e) {
                setShowModal({ enable: false });
            });
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(ModalContext.Provider, __assign({ value: {
            showModal: showModal,
            setShowModal: setShowModal,
        } }, { children: children })));
};
exports.ModalContextProvider = ModalContextProvider;
