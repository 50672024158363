"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaymentType = void 0;
var typePayment = {
    credit: "Cartão de Crédito",
    debit: "Cartão de Débito.",
    null: "Não informado",
};
var getPaymentType = function (type) { return typePayment[type]; };
exports.getPaymentType = getPaymentType;
