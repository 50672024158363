"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRegister = exports.useAuth = void 0;
var AuthContext_1 = require("@contexts/AuthContext/AuthContext");
var ConfigContext_1 = require("@contexts/ConfigContext/ConfigContext");
var react_1 = require("react");
var useAuth = function () {
    var _a = (0, react_1.useContext)(AuthContext_1.AuthContext), isAuthenticated = _a.isAuthenticated, user = _a.user, setUser = _a.setUser, signIn = _a.signIn, signOut = _a.signOut;
    return { isAuthenticated: isAuthenticated, user: user, setUser: setUser, signIn: signIn, signOut: signOut };
};
exports.useAuth = useAuth;
var useRegister = function () {
    var _a = (0, react_1.useContext)(ConfigContext_1.ConfigContext), hasRegistered = _a.hasRegistered, setHasRegistered = _a.setHasRegistered;
    return { hasRegistered: hasRegistered, setHasRegistered: setHasRegistered };
};
exports.useRegister = useRegister;
