"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLanguage = void 0;
var react_1 = require("react");
var LanguageContext_1 = require("@contexts/LanguageContext/LanguageContext");
var useLanguage = function () {
    var _a = (0, react_1.useContext)(LanguageContext_1.LanguageContext), language = _a.language, setLanguage = _a.setLanguage, languageInfo = _a.languageInfo;
    return { language: language, setLanguage: setLanguage, languageInfo: languageInfo };
};
exports.useLanguage = useLanguage;
