"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPlayerConfig = void 0;
var index_1 = require("@config/index");
var setPlayerConfig = function (_a) {
    var title = _a.title, userId = _a.userId, videoId = _a.videoId;
    var playerConfig = {
        key: (0, index_1.getKey)("PLAYER_KEY"),
        analytics: {
            key: (0, index_1.getKey)("ANALYTICS_KEY"),
            title: title,
            userId: userId,
            videoId: videoId,
        },
        cast: {
            enable: true,
        },
        playback: {
            autoplay: false,
        },
        style: {
        // aspectratio: '4:3',
        // height: "50vh",
        // width: "50vw",
        },
        adaptation: {
            desktop: {
                disableDownloadCancelling: true,
                limitToPlayerSize: true,
            },
            mobile: {
                disableDownloadCancelling: true,
                limitToPlayerSize: true,
            },
        },
        network: {
            retryHttpRequest: function (type, response, retries) {
                return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        resolve(response.request);
                    }, 1000);
                });
            },
        },
    };
    return playerConfig;
};
exports.setPlayerConfig = setPlayerConfig;
