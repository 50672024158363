"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var locale_1 = require("src/locale");
var useLanguage_1 = require("@hooks/useLanguage");
var CtDown = function (_a) {
    var data = _a.data, cor = _a.cor;
    var language = (0, useLanguage_1.useLanguage)().language;
    var countDownDate = new Date(data).getTime();
    var _b = (0, react_1.useState)(countDownDate - new Date().getTime()), countDown = _b[0], setCountDown = _b[1];
    (0, react_1.useEffect)(function () {
        var timer = countDown > 0 && setInterval(function () { return setCountDown(countDown - 60000); }, 60000);
        return function () { return clearInterval(timer); };
    }, [countDown]);
    var days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    var hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    var passado = countDown < 0 ? true : false;
    /*
    setInterval(function () {
      setCountDown(countDown - 60000);
    }, 60000);
  */
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: !passado ? ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center py-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "py-4 pr-4 text-sm ".concat(cor === "claro" ? "text-slate-500" : "text-slate-100", " ") }, { children: locale_1.LOCALE[language].EVENT.COUNT.TEXT1 })) })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex ".concat(cor === "claro" ? "text-slate-700" : "text-slate-100", " items-center text-xl") }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "py-1flex w-16 items-center justify-center px-6" }, { children: days })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 items-center justify-center px-6 py-1" }, { children: hours })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 items-center justify-center px-6 py-1" }, { children: minutes < 10 ? "0" + minutes : minutes }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex ".concat(cor === "claro" ? "bg-slate-100 text-slate-800" : "bg-slate-800 text-slate-400", " items-center") }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 items-center justify-center px-6 py-1 text-xs" }, { children: locale_1.LOCALE[language].EVENT.COUNT.TEXT2 })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 items-center justify-center px-6 py-1 text-xs" }, { children: locale_1.LOCALE[language].EVENT.COUNT.TEXT3 })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 items-center justify-center px-6 py-1 text-xs" }, { children: locale_1.LOCALE[language].EVENT.COUNT.TEXT4 }))] }))] })] }))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})) })));
};
exports.default = CtDown;
