"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCurrency = exports.formatPrice = void 0;
var formatPrice = function (price) {
    var formatted = price / 100;
    return formatted.toFixed(2);
};
exports.formatPrice = formatPrice;
var formatCurrency = function (currency, value) {
    if (!Number(value))
        return "";
    var amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: currency,
    }).format(value / 100);
    return "".concat(amount);
};
exports.formatCurrency = formatCurrency;
