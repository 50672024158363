"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ModalContext_1 = require("@contexts/ModalContext/ModalContext");
var default_1 = require("./template/default");
var Modal = function () {
    var _a = (0, react_1.useContext)(ModalContext_1.ModalContext), showModal = _a.showModal, setShowModal = _a.setShowModal;
    var renderTemplate = function () {
        switch (showModal.template) {
            case "term":
                return ((0, jsx_runtime_1.jsx)(default_1.TermTemplate, { setShowModal: function () { return setShowModal({ enable: !showModal.enable }); }, message: "Para prosseguir voce precisa estar de acordo com os termos.", title: "Termo de Aceite" }));
            case "denied":
                return ((0, jsx_runtime_1.jsx)(default_1.DeniedTemplate, { setShowModal: function () { return setShowModal({ enable: !showModal.enable }); }, message: "Acesso negado !", title: "Voce n\u00E3o tem acesso !" }));
            default:
                return ((0, jsx_runtime_1.jsx)(default_1.DefaultTemplate, { setShowModal: function () { return setShowModal({ enable: false }); }, message: showModal.message, title: showModal.title }));
        }
    };
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: showModal.enable ? renderTemplate() : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}) });
};
exports.default = Modal;
