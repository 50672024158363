"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth = exports.storage = exports.app = void 0;
var app_1 = require("firebase/app");
var auth_1 = require("firebase/auth");
var storage_1 = require("firebase/storage");
var _1 = require(".");
exports.app = (0, app_1.initializeApp)((0, _1.getKey)("FIREBASE_KEY"));
exports.storage = (0, storage_1.getStorage)(exports.app);
var auth = function () { return (0, auth_1.getAuth)(exports.app); };
exports.auth = auth;
