"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigContextProvider = exports.ConfigContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ConfigContext = (0, react_1.createContext)({});
exports.ConfigContext = ConfigContext;
var ConfigContextProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(true), showHeader = _b[0], setShowHeader = _b[1];
    var _c = (0, react_1.useState)(false), showLogin = _c[0], setShowLogin = _c[1];
    var _d = (0, react_1.useState)({ enable: false, message: "", title: "" }), showModal = _d[0], setShowModal = _d[1];
    var _e = (0, react_1.useState)(false), alreadyConnected = _e[0], setAlreadyConnected = _e[1];
    var _f = (0, react_1.useState)(false), hasRegistered = _f[0], setHasRegistered = _f[1];
    var hideHeader = function (hide) {
        setShowHeader(hide);
    };
    return ((0, jsx_runtime_1.jsx)(ConfigContext.Provider, __assign({ value: {
            alreadyConnected: alreadyConnected,
            setAlreadyConnected: setAlreadyConnected,
            hasRegistered: hasRegistered,
            setHasRegistered: setHasRegistered,
            showModal: showModal,
            setShowModal: setShowModal,
            showHeader: showHeader,
            setShowHeader: setShowHeader,
            hideHeader: hideHeader,
            showLogin: showLogin,
            setShowLogin: setShowLogin,
        } }, { children: children })));
};
exports.ConfigContextProvider = ConfigContextProvider;
