"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModal = void 0;
var react_1 = require("react");
var ModalContext_1 = require("@contexts/ModalContext/ModalContext");
var useModal = function () {
    var _a = (0, react_1.useContext)(ModalContext_1.ModalContext), setShowModal = _a.setShowModal, showModal = _a.showModal;
    return { setShowModal: setShowModal, showModal: showModal };
};
exports.useModal = useModal;
