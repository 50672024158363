"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myIcon = void 0;
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var free_regular_svg_icons_1 = require("@fortawesome/free-regular-svg-icons");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
exports.myIcon = {
    brands: { faStripe: free_brands_svg_icons_1.faStripe, faPix: free_brands_svg_icons_1.faPix, faGooglePay: free_brands_svg_icons_1.faGooglePay, faApplePay: free_brands_svg_icons_1.faApplePay, faGoogle: free_brands_svg_icons_1.faGoogle, faFacebookF: free_brands_svg_icons_1.faFacebookF, faTwitter: free_brands_svg_icons_1.faTwitter, faLinkedinIn: free_brands_svg_icons_1.faLinkedinIn, faInstagram: free_brands_svg_icons_1.faInstagram },
    react: { FontAwesomeIcon: react_fontawesome_1.FontAwesomeIcon },
    regular: {
        faImage: free_regular_svg_icons_1.faImage,
        faPlusSquare: free_regular_svg_icons_1.faPlusSquare,
        faMinusSquare: free_regular_svg_icons_1.faMinusSquare,
        faCalendar: free_regular_svg_icons_1.faCalendar,
        faCirclePlay: free_solid_svg_icons_1.faCirclePlay,
        faCircleCheck: free_solid_svg_icons_1.faCircleCheck,
    },
    solid: {
        faTicket: free_solid_svg_icons_1.faTicket,
        faCreditCard: free_solid_svg_icons_1.faCreditCard,
        faBullhorn: free_solid_svg_icons_1.faBullhorn,
        faShoppingCart: free_solid_svg_icons_1.faShoppingCart,
        faUserAstronaut: free_solid_svg_icons_1.faUserAstronaut,
        faXmark: free_solid_svg_icons_1.faXmark,
        faEllipsisVertical: free_solid_svg_icons_1.faEllipsisVertical,
        faUpRightAndDownLeftFromCenter: free_solid_svg_icons_1.faUpRightAndDownLeftFromCenter,
        faHandshake: free_solid_svg_icons_1.faHandshake,
        faLightbulb: free_solid_svg_icons_1.faLightbulb,
        faFlask: free_solid_svg_icons_1.faFlask,
        faUserGear: free_solid_svg_icons_1.faUserGear,
        faCopy: free_solid_svg_icons_1.faCopy,
        faVideo: free_solid_svg_icons_1.faVideo,
        faUserCheck: free_solid_svg_icons_1.faUserCheck,
        faLock: free_solid_svg_icons_1.faLock,
        faLockOpen: free_solid_svg_icons_1.faLockOpen,
        faComment: free_solid_svg_icons_1.faComment,
        faMoneyBillTransfer: free_solid_svg_icons_1.faMoneyBillTransfer,
        faHandHoldingHeart: free_solid_svg_icons_1.faHandHoldingHeart,
        faChevronUp: free_solid_svg_icons_1.faChevronUp,
        faChevronDown: free_solid_svg_icons_1.faChevronDown,
        faEye: free_solid_svg_icons_1.faEye,
        faArrowRotateForward: free_solid_svg_icons_1.faArrowRotateForward,
        faCheck: free_solid_svg_icons_1.faCheck,
        faTrash: free_solid_svg_icons_1.faTrash,
        faChevronLeft: free_solid_svg_icons_1.faChevronLeft,
        faChevronRight: free_solid_svg_icons_1.faChevronRight,
        faPenToSquare: free_solid_svg_icons_1.faPenToSquare,
        faUser: free_solid_svg_icons_1.faUser,
        faPlay: free_solid_svg_icons_1.faPlay,
        faAngleLeft: free_solid_svg_icons_1.faAngleLeft,
        faCartShopping: free_solid_svg_icons_1.faCartShopping,
        faPaperPlane: free_solid_svg_icons_1.faPaperPlane,
        faFileCircleCheck: free_solid_svg_icons_1.faFileCircleCheck,
        faArrowLeft: free_solid_svg_icons_1.faArrowLeft,
        faCalendar: free_regular_svg_icons_1.faCalendar,
        faExclamationCircle: free_solid_svg_icons_1.faExclamationCircle,
        faBriefcase: free_solid_svg_icons_1.faBriefcase,
        faTv: free_solid_svg_icons_1.faTv,
        faEarthAmericas: free_solid_svg_icons_1.faEarthAmericas,
        faChartLine: free_solid_svg_icons_1.faChartLine,
        faMobile: free_solid_svg_icons_1.faMobile,
        faUserPen: free_solid_svg_icons_1.faUserPen,
        faLaptop: free_solid_svg_icons_1.faLaptop,
    },
};
