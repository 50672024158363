"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var path_1 = require("../path");
var EventConsumer = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@components/evento")); }); });
var HomePage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/pronto/home")); }); });
var EventBuyPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/consumer/buy")); }); });
var EventPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/consumer/event")); }); });
var LoginPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/pronto/login")); }); });
var Terms = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/consumer/terms")); }); });
var Alias = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/consumer/alias")); }); });
var NotFoundPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/pronto/notfound")); }); });
var ResetPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/pronto/reset")); }); });
var WidgetPage = (0, react_1.lazy)(function () { return Promise.resolve().then(function () { return __importStar(require("@pages/pronto/widget")); }); });
var UnAuthorized = function () {
    return ((0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.DEFAULT, element: (0, jsx_runtime_1.jsx)(HomePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.EVENT_PAGE, element: (0, jsx_runtime_1.jsx)(EventPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.EVENT_PAGE_BUY, element: (0, jsx_runtime_1.jsx)(EventBuyPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.EVENT_PAGE_WATCH, element: (0, jsx_runtime_1.jsx)(EventConsumer, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.ENTRAR, element: (0, jsx_runtime_1.jsx)(LoginPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.TERMS, element: (0, jsx_runtime_1.jsx)(Terms, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.ALIAS, element: (0, jsx_runtime_1.jsx)(Alias, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.NOT_FOUND, element: (0, jsx_runtime_1.jsx)(NotFoundPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.RESET, element: (0, jsx_runtime_1.jsx)(ResetPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.EVENT_WIDGET_PAGE, element: (0, jsx_runtime_1.jsx)(WidgetPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path_1.PATH_UNAUTHORIZED.PRODUCER, element: (0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: path_1.PATH_UNAUTHORIZED.ENTRAR }) })] }));
};
exports.default = UnAuthorized;
