"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var icons_1 = require("@assets/icons");
var useLanguage_1 = require("@hooks/useLanguage");
var locale_1 = require("src/locale");
var NotFound = function () {
    var language = (0, useLanguage_1.useLanguage)().language;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-slate-900 sm:flex sm:flex-row sm:justify-evenly" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid h-full w-full max-w-7xl grid-cols-1 place-items-center gap-6 bg-gradient-to-b from-slate-600 to-slate-900 p-6 pt-16 sm:grid-cols-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex h-96 flex-col items-center justify-center rounded-xl p-6" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex h-40 w-40 items-center justify-center rounded-full bg-slate-800 shadow-2xl shadow-slate-900" }, { children: (0, jsx_runtime_1.jsx)(icons_1.myIcon.react.FontAwesomeIcon, { className: " text-8xl text-slate-200", icon: icons_1.myIcon.solid.faUserAstronaut }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "pt-4 text-xl font-bold text-slate-200 sm:text-3xl" }, { children: "P\u00E1gina n\u00E3o encontrada" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex h-40 flex-col items-center justify-center rounded-xl bg-slate-800 bg-opacity-20 p-6" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full text-center text-slate-200" }, { children: "Lorem ipsum dolor sit amet consectetur. Nulla nec et egestas cras aliquam ac cras ullamcorper." })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full pt-8 text-center text-slate-200" }, { children: [locale_1.LOCALE[language].ACTION.MY_TICKETS, " - Ajuda - FAQ"] }))] }))] })) })));
};
exports.default = NotFound;
