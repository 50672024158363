"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getKey = exports.FIREBASE_CLOUD_DEV = exports.FIREBASE_CLOUD_PROD = void 0;
var stripe_js_1 = require("@stripe/stripe-js");
var storage_1 = require("@utils/storage");
exports.FIREBASE_CLOUD_PROD = "https://us-central1-devideo-application.cloudfunctions.net";
exports.FIREBASE_CLOUD_DEV = "https://us-central1-devideo.cloudfunctions.net";
var keysProd = {
    STRIPE_KEY: (0, stripe_js_1.loadStripe)("pk_live_51LjjlvCOOVOrv0iBUyebhc4wV5QbrJMb4sDqmo3AhwjVC5XYxHBF3fpxhLiBRoTQxK4F6uR6GOJCYumbdpoebZwa00Br3yNLSm"),
    PLAYER_KEY: "053E3E58-E65C-448B-94AC-8D67D05EA738",
    ANALYTICS_KEY: "89709FA5-2BB6-4552-9AD1-931FF57CA3ED",
    SERVER_CHAT: "https://devideo-websocket-chat-ivxkayfoza-uc.a.run.app",
    TINY_API: "r2phhaxqmf90qzd15x2vv44o9ku52keoeho2ilr2vax87tg3",
    AUTH_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_auth"),
    BACKEND_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend"),
    COMPRADOR_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_comprador"),
    CONSUMER_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_consumer_event"),
    EVENT_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_event"),
    PAYMENT_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_payment"),
    PRODUCER_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_producer"),
    WALLET_API: "".concat(exports.FIREBASE_CLOUD_PROD, "/backend_wallet"),
    FIREBASE_KEY: {
        apiKey: "AIzaSyCXfTQM8aYos6UzvnW1U6OJ14CYjaEF69I",
        authDomain: "devideo-application.firebaseapp.com",
        projectId: "devideo-application",
        storageBucket: "devideo-application.appspot.com",
        messagingSenderId: "28812791618",
        appId: "1:28812791618:web:ee170a856cc2e59537c2db",
        measurementId: "G-HZVKLHN3FD",
    },
};
var keysDev = {
    STRIPE_KEY: (0, stripe_js_1.loadStripe)("pk_test_51LjjlvCOOVOrv0iBGfJLpeGGhJwtjhEPBWjDrQarYJUDFAS6R1Xbvzg2TkMm7jrJXDmEfpnwFdpuQBqYDVWF0vxD00QggWAC0B"),
    PLAYER_KEY: "053E3E58-E65C-448B-94AC-8D67D05EA738",
    ANALYTICS_KEY: "89709FA5-2BB6-4552-9AD1-931FF57CA3ED",
    SERVER_CHAT: "https://devideo-websocket-chat-ivxkayfoza-uc.a.run.app",
    TINY_API: "r2phhaxqmf90qzd15x2vv44o9ku52keoeho2ilr2vax87tg3",
    AUTH_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_auth"),
    BACKEND_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend"),
    COMPRADOR_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_comprador"),
    CONSUMER_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_consumer_event"),
    EVENT_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_event"),
    PAYMENT_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_payment"),
    PRODUCER_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_producer"),
    WALLET_API: "".concat(exports.FIREBASE_CLOUD_DEV, "/backend_wallet"),
    FIREBASE_KEY: {
        apiKey: "AIzaSyD44Iefu-kBZCQJeLdzdTmmIrZW2unZ-sE",
        authDomain: "devideo.firebaseapp.com",
        databaseURL: "https://devideo-default-rtdb.firebaseio.com",
        projectId: "devideo",
        storageBucket: "devideo.appspot.com",
        messagingSenderId: "232520962891",
        appId: "1:232520962891:web:b6bf8a380e9df2d6d20ece",
        measurementId: "G-KYFX6T8WNQ",
    },
};
var getKey = function (path) {
    var env = (0, storage_1.getLocalStorage)("enviroment") !== "dev" ? keysProd[path] : keysDev[path];
    return env;
};
exports.getKey = getKey;
